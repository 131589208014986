@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.center {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
  flex-direction: column;
}

.noSelect {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.scrollbar {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: rgb(3, 7, 24) lightgrey; /* Thumb and track colors for Firefox */
}

.scrollBar::-webkit-scrollbar-track{
  background-color: red;
}



